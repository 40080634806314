import React, { useRef } from 'react';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import InputMask from "react-input-mask";
import * as yup from "yup";
import axios from "axios";

const ContactForm = () => {
    const system = process.env.GATSBY_SYSTEM_API;
    const formSchema = yup.object().shape({
        Name: yup.string().required("Preencha o campo"),
        Email: yup.string().email("Digite um endereço de email válido").required("Preencha o campo"),
        Phone: yup.string(),
        Subject: yup.string().required("Preencha o campo"),
        Message: yup.string().required("Preencha o campo"),
    });

    const { control, handleSubmit, errors } = useForm({
        resolver: yupResolver(formSchema)
    });

    const btnSubmit = useRef();
    const onSubmit = (values) => {
        btnSubmit.current.disabled = true;
        let objSubmit = {
            Name: values.Name,
            Email: values.Email,
            Subject: values.Subject,
            Message: values.Message,
        };
        if (values.Phone) objSubmit.Phone = values.Phone;
        axios
        .post(`${system}/app/contact`, objSubmit)
        .then(res => {
                console.log(res.status);
                alert('Enviado com sucesso!');
                // btnSubmit.current.disabled = false;
            })
            .catch(err => console.log(err));
    };

    return (
        <section className="contact" id="contato">
            <h1 className="title white-font bold text-center">Entre em contato</h1>
            <p className="description white-font text-center">Deixe sua mensagem, com sua dúvida, crítica ou sugestão.</p>
            <p className="description white-font text-center">O Gifthy agradece e responderá o mais rapidamente possível.</p>
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
                <div className="wrapper">
                    <div>
                        <label htmlFor="Name">Nome</label>
                        <Controller
                            as={<input />}
                            className="name"
                            placeholder="Seu nome"
                            name="Name"
                            control={control}
                            defaultValue=""
                        />
                        {errors.Name && <label className="error-label">{errors.Name.message}</label>}
                    </div>
                    <div>
                        <label htmlFor="Email">Email</label>
                        <Controller
                            as={<input />}
                            className="email"
                            placeholder="Seu email"
                            name="Email"
                            control={control}
                            defaultValue=""
                        />
                        {errors.Email && <label className="error-label">{errors.Email.message}</label>}
                    </div>
                    <div>
                        <label htmlFor="Phone">Telefone</label>
                        <Controller
                            as={
                                <InputMask mask="(99) 99999-9999" maskChar=" " />
                            }
                            className="phone"
                            placeholder="Seu telefone"
                            name="Phone"
                            control={control}
                            defaultValue=""
                        />
                        {errors.Phone && <label className="error-label">{errors.Phone.message}</label>}
                    </div>
                    <div>
                        <label htmlFor="Subject">Assunto</label>
                        <Controller
                            as={<input />}
                            className="subject"
                            placeholder="Assunto da mensagem"
                            name="Subject"
                            control={control}
                            defaultValue=""
                        />
                        {errors.Subject && <label className="error-label">{errors.Subject.message}</label>}
                    </div>
                    <div className="message-box">
                        <label htmlFor="Message">Mensagem</label>
                        <Controller
                            as={<textarea />}
                            className="message"
                            placeholder="Sua mensagem"
                            name="Message"
                            control={control}
                        />
                        {errors.Message && <label className="error-label">{errors.Message.message}</label>}
                    </div>
                </div>
                <button className="submit-button white-font bold" ref={btnSubmit} type="submit">Enviar mensagem</button>
            </form>
        </section>
    )
};

export default ContactForm;